import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

import DatabaseDev from "../images/database-development.svg";
import WebDev from "../images/web-development.svg";
import MobileDev from "../images/mobile-applications.svg";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      mobileAndUpHeaderImage: file(relativePath: {eq: "elevate-user-experience@3x.png"}) {
        childImageSharp {
          fixed(width: 220, height: 140, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      tabletAndUpHeaderImage: file(relativePath: {eq: "elevate-user-experience@3x.png"}) {
        childImageSharp {
          fixed(width: 250, height: 260 quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      mobileAndUpArticleOne: file(relativePath: {eq: "enriched-with-experience@3x.png"}) {
        childImageSharp {
          fixed(width: 260, height: 219, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      tabletAndUpArticleOne: file(relativePath: {eq: "enriched-with-experience@3x.png"}) {
        childImageSharp {
          fixed(width: 340, height: 258, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      mobileAndUpArticleTwo: file(relativePath: {eq: "bettering-our-engineers@3x.png"}) {
        childImageSharp {
          fixed(width: 286, height: 193, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      tabletAndUpArticleTwo: file(relativePath: {eq: "bettering-our-engineers@3x.png"}) {
        childImageSharp {
          fixed(width: 337, height: 229, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const articleOneSources = [
    data.mobileAndUpArticleOne.childImageSharp.fixed,
    {
      ...data.tabletAndUpArticleOne.childImageSharp.fixed,
      media: `(min-width: 1024px)`,
    },
  ]
  const articleTwoSources = [
    data.mobileAndUpArticleTwo.childImageSharp.fixed,
    {
      ...data.tabletAndUpArticleTwo.childImageSharp.fixed,
      media: `(min-width: 1024px)`,
    },
  ]
  const headerSources = [
    data.mobileAndUpHeaderImage.childImageSharp.fixed,
    {
      ...data.tabletAndUpHeaderImage.childImageSharp.fixed,
      media: `(min-width: 1024px)`,
    },
  ]

  function smoothScroll() {
    const el = document.querySelector('#our-services');
    window.scrollTo({
      behavior: 'smooth',
      top: el.offsetTop - 85,
    })
  }

  return (
    <Layout>
      <SEO title="Welcome" />
      <header className="page-header landing">
        <div className="header-image-container">
          <Image fixed={headerSources} />
        </div>
        <div className="header__inner-container">
          <div className="logo">
            <h1>Prasthana</h1>
            <p>Software Solutions</p>
          </div>
          <div>
            <p>We build technology to <span className="highlight">elevate</span> your business’s user experience.</p>
            <button type="button" className="button primary on-dark-background" onClick={smoothScroll}>Our Services</button>
          </div>
        </div>
      </header>
      <section id="our-services" className="content highlighted">
        <h2>Our Services</h2>
        <section className="full-to-third feature-container">
          <article className="col feature">
            <WebDev />
            <div>
              <h3>Web Development</h3>
              <p>We work with you to develop a frictionless user-centered experience that delivers value to your audience based on your goals.</p>
            </div>
          </article>
          <article className="col feature">
            <DatabaseDev />
            <div>
              <h3>Database Development</h3>
              <p>We use database technology to identify, process and store key information about your customers to help you drive your business growth. </p>
            </div>
          </article>
          <article className="col feature">
            <MobileDev />
            <div>
              <h3>Mobile Development</h3>
              <p>We develop high-performance mobile applications with the option of cross-platform adaptability to increase your business’s channels.</p>
            </div>
          </article>
        </section>
      </section>
      <section className="content feature-container article-container full-to-half">
        <article className="article-1 col">
          <div className="feature-article">
            <h2>Enriched with experience across multiple industries</h2>
            <p>We’ve worked with multiple clients across the fields of healthcare, wellness, infra industries as well as other technology companies. We have a continuously strengthened internal development process that allows us to apply our team collaboration and streamlined workflows to complete projects across diverse industries.</p>
          </div>
          <Image fixed={articleTwoSources} />
        </article>
        <article className="article-2 feature-article col">
          <div className="feature-article">
            <h2>Bettering our engineers for better products.</h2>
            <p>Our journey towards trusted quality work starts with our engineers. We facilitate an environment that allows our engineers to grow into experts in what they’re most passionate about. In turn, our holistic approach creates a path that seamlessly translates over to our client’s experiences and the products we produce for their consumers. </p>
          </div>
          <Image fixed={articleOneSources} />
        </article>
      </section>
    </Layout>
  )
}

export default IndexPage
